import { ERROR_PAGE_LINKS } from 'src/components/ErrorPage/error-page-links';

import { Box, Button, Grid, Heading, Link, List, ListItem } from '@chakra-ui/react';
import { BiChevronRight } from 'react-icons/bi';

type ErrorPageProps = {
  title?: string;
  subtitle?: string;
};

const ErrorPage = ({ title = `Sorry, we couldn't find that page`, subtitle }: ErrorPageProps) => {
  return (
    <Grid data-testid="loading-page" alignItems="center" gap={12} templateColumns="1fr 300px">
      <Box>
        <Heading mb={8} size="3xl">
          {title}
        </Heading>
        {subtitle && (
          <Heading size="xl" fontWeight="normal">
            {subtitle}
          </Heading>
        )}
      </Box>
      <Box>
        <List fontWeight="bold" spacing={6}>
          {ERROR_PAGE_LINKS.map((link) => (
            <ListItem key={link.title}>
              <Button as={Link} variant="link" rightIcon={<BiChevronRight />} href={link.url}>
                {link.title}
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
    </Grid>
  );
};

export default ErrorPage;
