import { AuthState, SetCurrentUserPayload } from 'src/states/auth/types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: AuthState = {
  isReady: false,
};

const authStore = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<SetCurrentUserPayload>) => {
      state.currentUser = {
        name: action.payload.name,
        email: action.payload.email,
        token: action.payload.token,
      };
      state.isReady = true;
    },
    clearCurrentUser: (state) => {
      state.currentUser = undefined;
      state.isReady = true;
    },
  },
});

export default authStore;
