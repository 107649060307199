export type ErrorLink = {
  title: string;
  url: string;
};

export const ERROR_PAGE_LINKS: Array<ErrorLink> = [
  {
    title: 'Splunk Security Solutions',
    url: 'https://www.splunk.com/en_us/cyber-security.html',
  },
  {
    title: 'Splunk IT Solutions',
    url: 'https://www.splunk.com/en_us/it.html',
  },
  {
    title: 'Splunk DevOps Solutions',
    url: 'https://www.splunk.com/en_us/devops.html',
  },
  {
    title: 'Industry Solutions',
    url: 'https://www.splunk.com/en_us/solutions/industries.html',
  },
  {
    title: 'Splunk Products',
    url: 'https://www.splunk.com/en_us/software.html',
  },
];
