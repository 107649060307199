import { ReactHoc } from 'src/types/hoc';

import { BrowserRouter } from 'react-router-dom';

const withReactRouter: ReactHoc = (Component) => (props) =>
  (
    <BrowserRouter>
      <Component {...props} />
    </BrowserRouter>
  );

export default withReactRouter;
