import { Box, CircularProgress, Text } from '@chakra-ui/react';

type LoadingPageProps = {
  message?: string;
};

const LoadingPage: React.FC<LoadingPageProps> = ({ message }) => (
  <Box
    data-testid="loading-page"
    width="100%"
    display="flex"
    height="100vh"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    position="absolute"
    top="0"
    left="0"
    bgColor="white"
    zIndex="9999"
  >
    <CircularProgress size={100} color="primary.500" mb={4} isIndeterminate />
    <Text color="gray.500">{message || `Loading...`}</Text>
  </Box>
);

export default LoadingPage;
