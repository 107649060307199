import reducers from 'src/states/reducers';
import { RootState } from 'src/states/types';

import { Middleware, StoreEnhancer } from '@reduxjs/toolkit';
import { AnyAction, Reducer, Store, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const bindMiddleware = (middlewareArray: Middleware[]): StoreEnhancer =>
  composeWithDevTools(applyMiddleware(...middlewareArray));

const makeStore = (storeReducers: Reducer<RootState, AnyAction>): Store<RootState> =>
  createStore(storeReducers, bindMiddleware([]));

const store = makeStore(reducers);

export default store;
