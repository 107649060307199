import { Suspense, lazy } from 'react';

import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import LoadingPage from 'src/components/LoadingPage/LoadingPage';

import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';

const EulaAcceptancePage = lazy(() => import('src/pages/EulaAcceptancePage/EulaAcceptancePage'));
const NotAvailablePage = lazy(() => import('src/pages/NotAvailablePage/NotAvailablePage'));

const AppRoutes = () => (
  <ErrorBoundary
    fallback={
      <ErrorPage
        title="There was an error loading this page"
        subtitle="Please try again in some minutes."
      />
    }
  >
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="accept" element={<EulaAcceptancePage />} />
        <Route path="*" element={<NotAvailablePage />} />
      </Routes>
    </Suspense>
  </ErrorBoundary>
);

export default AppRoutes;
