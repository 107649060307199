import AppRoutes from 'src/components/AppRoutes/AppRoutes';
import withChakraUI from 'src/hocs/with-chakra-ui';
import withReactQuery from 'src/hocs/with-react-query';
import withReactRouter from 'src/hocs/with-react-router';
import withRedux from 'src/hocs/with-redux';

import { compose } from 'recompose';

const App = (): JSX.Element => <AppRoutes />;

export default compose(withChakraUI, withReactQuery, withRedux, withReactRouter)(App);
