import theme from 'src/styles/theme';
import { ReactHoc } from 'src/types/hoc';

import { ChakraProvider } from '@chakra-ui/react';

const withChakraUI: ReactHoc = (Component) => (props) =>
  (
    <ChakraProvider theme={theme}>
      <Component {...props} />
    </ChakraProvider>
  );

export default withChakraUI;
