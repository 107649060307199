import store from 'src/states/store';
import { ReactHoc } from 'src/types/hoc';

import { Provider } from 'react-redux';

const withRedux: ReactHoc = (Component) => (props) =>
  (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );

export default withRedux;
