import { SystemStyleObject, extendTheme } from '@chakra-ui/react';

const buttonBaseStyle: SystemStyleObject = {
  borderRadius: '2xl',
  _disabled: {
    bgColor: 'gray.300',
  },
  _hover: {
    _disabled: {
      bgColor: 'gray.300',
    },
  },
};

const linkBaseStyle: SystemStyleObject = {
  color: 'primary.500',
  _hover: {
    color: 'primary.600',
  },
};

const checkboxControlBaseStyle: SystemStyleObject = {
  border: '1px solid',
  marginTop: 3,
  _checked: {
    borderColor: 'primary.500',
    backgroundColor: 'primary.500',
  },
};

const checkboxIconBaseStyle: SystemStyleObject = {
  color: 'white',
};

const theme = extendTheme({
  colors: {
    primary: {
      50: '##ffe5f3',
      100: '#ffb3dc',
      200: '#ff80c4',
      300: '#ff4dad',
      400: '#ff1a95',
      500: '#e6007c',
      600: '#b30060',
      700: '#800045',
      800: '#4d0029',
      900: '#1a000e',
    },
  },
  components: {
    Button: {
      baseStyle: buttonBaseStyle,
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Link: {
      baseStyle: linkBaseStyle,
    },
    Checkbox: {
      defaultProps: {
        // Setting default color Schema
        colorScheme: 'primary.500',
      },
      baseStyle: {
        control: checkboxControlBaseStyle,
        icon: checkboxIconBaseStyle,
      },
    },
  },
});

export default theme;
